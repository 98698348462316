<template>
  <div
    class="flex text-center card"
    style="min-height: 80px; width: 100%; max-width: 1400px"
    :style="{
      backgroundImage: `linear-gradient(to bottom, ${this.color}, #050404)`,
    }"
  >
    <div
      class="row carrousel-images text-center centered"
      style="margin-right: auto; left: 0; right: 0;min-height: 80px"
    >
      <div v-for="(photo, idx) in this.images" :key="idx">
        <img
          style="border-radius: 0px; cursor: pointer"
          class="carrousel-image"
          :class="photo === image ? 'active' : ''"
          height="80px"
          width="80px"
          :alt="imageCaptions[idx] || 'carrousel image'"
          v-lazy="photo + '?w=80&h=80&fit=fillmax&fill=blur&auto=format'"
          @click="viewImage(idx, photo)"
        />
      </div>
    </div>
    <p class="centered" style="margin-top: -35px">{{this.title}}</p>
  </div>
</template>
<script>
import {MEDIA_BASE_URL} from '@/common/request';

export default {
  name: 'MiniCarrousel',
  created() {
    if (this.folder) {
      fetch(
        encodeURI(
          `${MEDIA_BASE_URL}?props=imgix_url,metadata&read_key=7pMG22sp4oEfuIRswb3c9kfsxlfxa9vxrGAOUBJttBwiP4FkGQ&query={"folder": "${this.folder}"}`
        )
      )
        .then((response) => {
          response.json().then((r) => {
            let sorted = [...r.media].sort((x, y) =>
              (x?.metadata?.order || 100) > (y?.metadata?.order || 99) ? 1 : -1
            );
            this.images = sorted.map((x) => x.imgix_url);
            if(this.numPhotos) {
              this.images = this.images.slice(0 , this.numPhotos);
            }
            this.preCaptions = sorted.map((x) =>
              x.metadata ? x.metadata.caption : '- -'
            );
            this.viewImage(0, this.images[0]);
          });
        })
        .catch((x) => {
          console.error(x);
        });
    } else {
      this.images = this.photos;
      if(this.numPhotos) {
        this.images = this.images.slice(0 , this.numPhotos);
      }
    }
  },
  data: () => {
    return {
      loaded: false,
      largeImage: null,
      imageCaption: null,
      preCaptions: ['default'],
      images: null,
      faqsImg:
        'https://imgix.cosmicjs.com/3c6580f0-6c2c-11ee-ae6f-f1c9a9c95b9c-image.png',
    };
  },
  methods: {
    viewImage(idx, image) {
      this.largeImage = image;
      this.imageCaption = this.captions[idx]
        ? this.captions[idx]
        : this.imageCaptions[idx];
    },
  },
  computed: {
    image: {
      get() {
        return (
          this.largeImage ||
          (this.images
            ? this.images[0]
            : 'https://imgix.cosmicjs.com/3d235160-ccd1-11ee-9ce5-59949019255e-loading.jpeg')
        );
      },
    },
    caption: {
      get() {
        return (
          this.imageCaption ||
          (this.preCaptions ? this.preCaptions[0] : 'tulum party photos')
        );
      },
    },
    imageCaptions: {
      get() {
        return this.preCaptions || [];
      },
    },

  },
  props: {
    photos: {
      type: Array, // striped | hover
      default: () => [''],
    },
    captions: {
      type: Array, // striped | hover
      default: () => [''],
    },
    title: {
      type: String,
      default: null,
    },
    numPhotos: {
      type: Number,
      default: 6,
    },
    color: {
      type: String,
      default: null,
    },
    folder: {
      type: String,
      default: null,
    },
  },
};
</script>
